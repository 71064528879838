.brand-divosta {
    // logo
    --logo-background: url("/assets/divosta/logo.svg");
    --logo-width: 169px;
    --logo-height: 28px;

    // fonts
    --font-family: "StagSans", Helvetica, sans-serif;

    // colors
    --brand-primary: #0b7699;

    --brand-white: #fff;

    --overlay-white: rgba(255, 255, 255, 0.8);

    --gray-base: #000;
    --gray-darker: #222;
    --gray-dark: #333;
    --gray: #555;
    --gray-light: #777;
    --gray-lighter: #eee;
    --gray-custom-filter: #b1b1b1;

    --text-color: #343434;

    // button colors and styles
    --btn-default-color: #333;
    --btn-default-bg: #fff;
    --btn-default-border: var(--btn-default-color);

    --btn-primary-color: #fff;
    --btn-primary-bg: #004f85;
    --btn-primary-border:#00406b;
    --btn-primary-hover-bg: rgba(0,79,133,.8);

    --btn-link-disabled-color: var(--gray-light);

    --btn-border-radius-base: 35px;

    // light link colors and styles
    --light-link: var(--brand-white);
    --light-link-hover: #b3b3b3;
}