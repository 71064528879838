/*
 * Global Button Styles
 *
 */

.btn {
    padding: 20px 35px;
    min-width: 130px;
    font-size: 1em;
    letter-spacing: 0.4px;
    margin-right: 10px;
    border-radius: var(--btn-border-radius-base);

    &:last-of-type{
        margin-right: 0;
    }
  
    @include transition(background-color 150ms ease-in-out);
  
    @include hover {
        @include transition(background-color 150ms ease-in-out);
    }
}

.btn.disabled,
.btn[disabled] {
    opacity: 1;
    background-color: var(--gray-lighter);
    color: var(--text-color);
    cursor: not-allowed;
}

.btn-primary {
    color: var(--btn-primary-color);
    background-color: var(--btn-primary-bg);
    border: 1px solid var(--btn-primary-bg);

    @include hover {
        background-color: var(--btn-primary-hover-bg) !important;
    }
}

.btn-primary.disabled,
.btn-primary[disabled] {
    border-color: var(--btn-primary-border);
}