.brand-centex {
    // logo
    --logo-background: url("/assets/centex/logo.svg");
    --logo-width: 119px;
    --logo-height: 25px;

    // fonts
    --font-family: "Apercu", Helvetica, sans-serif;

    // colors
    --brand-primary: #ce4235;

    --brand-white: #fff;

    --overlay-white: rgba(255, 255, 255, 0.8);

    --gray-base: #000;
    --gray-darker: #222;
    --gray-dark: #333;
    --gray: #555;
    --gray-light: #777;
    --gray-lighter: #eee;
    --gray-custom-filter: #b1b1b1;

    --text-color: #343434;

    // button colors and styles
    --btn-default-color: #333;
    --btn-default-bg: #fff;
    --btn-default-border: var(--gray-dark);

    --btn-primary-color: #fff;
    --btn-primary-bg: #74c1df;
    --btn-primary-border:#5fb8da;
    --btn-primary-hover-bg: rgba(116,193,223,.8);

    --btn-link-disabled-color: var(--gray-light);

    --btn-border-radius-base: 35px;

    // light link colors and styles
    --light-link: var(--brand-white);
    --light-link-hover: #b3b3b3;
}