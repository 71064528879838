.brand-jw {
    // logo
    --logo-background: url("/assets/jw/logo.svg");
    --logo-width: 140px;
    --logo-height: 44.4px;

    // fonts
    --font-family: "Palatino-Linotype","Palatino Linotype", Georgia, "Times New Roman", Times, serif;

    // colors
    --brand-primary: #002855;

    --brand-white: #fff;

    --overlay-white: rgba(255, 255, 255, 0.8);

    --gray-base: #000;
    --gray-darker: #222;
    --gray-dark: #333;
    --gray: #555;
    --gray-light: #777;
    --gray-lighter: #eee;
    --gray-custom-filter: #b1b1b1;

    --text-color: #343434;

    // button colors and styles
    --btn-default-color: #333;
    --btn-default-bg: #fff;
    --btn-default-border: var(--btn-default-color);

    --btn-primary-color: #fff;
    --btn-primary-bg: #00346e;
    --btn-primary-border:#002854;
    --btn-primary-hover-bg: rgba(0,52,111,.8);

    --btn-link-disabled-color: var(--gray-light);

    --btn-border-radius-base: 35px;

    // light link colors and styles
    --light-link: var(--brand-white);
    --light-link-hover: #b3b3b3;
}